import {
  AddShoppingCart,
  CheckOutlined,
  Delete,
  ErrorOutline,
  NavigateNext,
  Remove,
  Star,
  StarOutline,
  Visibility,
} from "@mui/icons-material";
import {
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Tooltip,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Breadcrumbs,
  TableBody,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { environment } from "env";
import EasyRequestService from "modules/EasyRequest/service/easyRequest.service";
import { PUIContentCarousel } from "modules/UI/components/ContentCarousel.component";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { PslMaterial } from "../domains/pslMaterial.domain";
import PromoItemRow, {
  PromoItemRowSchema,
  promoItemFields,
} from "./PromoItemRow.component";
import Drawer from "modules/UI/components/Drawer.component";
import StaticResources from "service/dummyData/resources";
import { NavLink } from "react-router-dom";
import moment from "moment";

export const PromoItemDetails = ({
  open,
  onClose,
  promoItem,
}: {
  open?: boolean;
  onClose?: () => void;
  promoItem: any;
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: addToCart, status: addToCartStatus } = useMutation({
    mutationKey: ["addToCart"],
    mutationFn: async () => {
      return EasyRequestService.addPslMaterialById({
        materialId: promoItem.materialId,
      });
    },
    onSuccess: (res) => {
      queryClient.refetchQueries({ queryKey: ["easyRequestCounter"] });
      toast.success(
        `Material added to PR ${res[0].prId}. Click here to see Cart.`,
        {
          onClick: () => {
            window.open(environment.EASY_REQUEST_URL, "_blank");
          },
        }
      );
    },
  });
  return (
    <Drawer
      open={open}
      onClose={() => onClose()}
      headerContent={<></>}
      footerContent={
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {!!promoItem &&
            !!promoItem?.unitPrice &&
            !!promoItem?.currencyCode &&
            !!promoItem?.pricePer &&
            !!promoItem?.unitOfMeasure && (
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  flexGrow: "0",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "100%",
                  display: "block",
                }}
              >
                {promoItem?.unitPrice} {promoItem?.currencyCode} per{" "}
                {promoItem?.pricePer} {promoItem?.unitOfMeasure}
              </Typography>
            )}
          {!!promoItem && PslMaterial.build(promoItem).canBeRequested() && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{ml: "auto"}}
              startIcon={
                {
                  pending: <CircularProgress size={14} />,
                  success: <CheckOutlined />,
                  error: <ErrorOutline />,
                }[addToCartStatus] || <AddShoppingCart />
              }
              disabled={addToCartStatus === "pending"}
              onClick={() => {
                addToCart();
              }}
            >
              Add To Cart
            </Button>
          )}
        </Box>
      }
    >
      {" "}
      {!!promoItem && (
        <Box>
          <PUIContentCarousel
            data={promoItem?.mediaFiles.map((file) => ({
              type: "image",
              thumbnail: file.url,
              src: file.url,
            }))}
          />
          {/* <Typography variant="body1">
            Browse / {
              StaticResources.promoCategories.find(
                (cat) => cat.id == promoItem?.promoCategoryId
              )?.name
            }
          </Typography> */}
          <Breadcrumbs
            sx={{ my: 2 }}
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            {[
              <NavLink key="1" color="inherit" to="/browse" onClick={() => {}}>
                Browse
              </NavLink>,
              <NavLink
                key="1"
                color="inherit"
                to={`/browse?catId=${promoItem?.promoCategoryId}`}
                onClick={() => {}}
              >
                {
                  StaticResources.promoCategories.find(
                    (cat) => cat.id == promoItem?.promoCategoryId
                  )?.name
                }
              </NavLink>,
            ]}
          </Breadcrumbs>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {promoItem?.shortDescription}
            {/* [{promoItem?.materialId}] {promoItem?.materialName} */}
          </Typography>
          <Typography variant="body1">{promoItem?.supplierName}</Typography>
          <Typography variant="body2">{promoItem?.description}</Typography>
          <Grid container spacing={2} sx={{ py: 6 }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Table>
                  <TableBody>
                    {[
                      {
                        name: "Promo ID",
                        value: promoItem?.pslIdWithPrefix,
                      },
                      {
                        name: "Status",
                        value: promoItem?.statusName,
                      },
                      // {
                      //   name: "Short Description",
                      //   value: promoItem?.shortDescription,
                      // },
                      // {
                      //   name: "Item Description",
                      //   value: promoItem?.itemDescription,
                      // },
                      {
                        name: "Unit Price",
                        value: promoItem?.unitPrice,
                      },
                      {
                        name: "Currency",
                        value: promoItem?.currencyCode,
                      },
                      {
                        name: "Price Per",
                        value: promoItem?.pricePer,
                      },
                      {
                        name: "Unit of measure",
                        value: promoItem?.unitOfMeasure,
                      },
                      {
                        name: "Valid From",
                        value: moment(promoItem?.validFrom).format(
                          "DD/MM/YYYY"
                        ),
                      },
                      {
                        name: "Valid To",
                        value: moment(promoItem?.validTo).format("DD/MM/YYYY"),
                      },
                      {
                        name: "Agreements",
                        value: promoItem?.agreements,
                      },
                      {
                        name: "Strategic Buyer",
                        value: promoItem?.strategicBuyerEmailAddress,
                      },
                      {
                        name: "Created By",
                        value: promoItem?.createdByUser?.email,
                      },
                      {
                        name: "Created On",
                        value: moment(promoItem?.createdOn).format(
                          "DD/MM/YYYY"
                        ),
                      },
                      {
                        name: "Modified By",
                        value: promoItem?.modifiedByUser?.email,
                      },
                      {
                        name: "Modified On",
                        value: moment(promoItem?.modifiedOn).format(
                          "DD/MM/YYYY"
                        ),
                      },
                    ].map((field) => (
                      <TableRow>
                        <TableCell>
                          <strong>{field.name}</strong>
                        </TableCell>
                        <TableCell>{field.value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Drawer>
  );
};

export default PromoItemDetails;
