import {
  Close,
  Collections,
  ContentCopy,
  ContentCopyTwoTone,
  FileCopy,
  Remove,
} from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useFormik, useFormikContext } from "formik";
import { set } from "ramda";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import StaticResources from "service/dummyData/resources";
import { toTitleCase } from "utils/utils";
import * as yup from "yup";
import PromoItemsService from "../services/PromoItems.service";
import { MuiChipsInput } from "mui-chips-input";
import { WaysOfBuyingComponent } from "./WaysOfBuying.component";
import { CustomFastSelect } from "modules/Forms/components/form.legacy.component";
import { useActiveUser } from "modules/User/activeUser";

const CustomCurrencySelect = ({ value, onChange }) => {
  const { data: currencies, isLoading: isLoadingCurrencies } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => PromoItemsService.fetchCurrencies(),
    initialData: [{ CurrencyKey: "CHF", Description: "CHF" }],
  });
  return (
    <FormControl fullWidth size="small">
      <InputLabel htmlFor="grouped-select">Currency</InputLabel>
      <Select
        size="small"
        fullWidth
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        label="Age"
        onChange={(e) => onChange(e.target.value)}
      >
        {currencies.map((currency) => {
          return (
            <MenuItem value={currency.CurrencyKey}>
              {currency.Description}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const CustomLanguageSelect = ({ value, onChange, required, error }) => {
  const { data: languages, isLoading: isLoadingLanguages } = useQuery({
    queryKey: ["languages"],
    queryFn: () => PromoItemsService.fetchLanguages(),
    initialData: [],
  });
  return (
    <FormControl fullWidth size="small" disabled={!!isLoadingLanguages}>
      <InputLabel htmlFor="grouped-select">
        Language {required && "*"}
      </InputLabel>
      <Select
        size="small"
        fullWidth
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        label="Age"
        error={!!error}
        onChange={(e) => onChange(e.target.value)}
      >
        {languages.map((language) => {
          return <MenuItem value={language.id}>{language.name}</MenuItem>;
        })}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

const CustomMaterialSelect = ({ value, onChange, values, promoItem }) => {
  const { data: amdrMaterials, isLoading: isLoadingAmdrMaterials } = useQuery({
    queryKey: ["amdrMaterials", promoItem.spendCat3Code],
    queryFn: () =>
      PromoItemsService.getPslAmdrMaterials({
        materialgroup: promoItem.spendCat3Code,
      }),
    initialData: [],
  });
  return (
    <FormControl fullWidth size="small" disabled={!!isLoadingAmdrMaterials}>
      <InputLabel>Material number</InputLabel>
      <Select
        size="small"
        fullWidth
        value={value}
        label="Age"
        onChange={(e) => onChange(e.target.value)}
      >
        {amdrMaterials.map((material) => {
          return (
            <MenuItem value={material.id}>
              [{material.id}] {material.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const promoItemFields = [
  {
    type: "custom",
    field: "promoCategoryId",
    label: "Promo Category",
    render: (field, value, onChange) => {
      return (
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="grouped-select">Promo Category</InputLabel>
          <Select
            defaultValue=""
            id="grouped-select"
            label="Promo Category"
            value={value}
            readOnly={!onChange}
            onChange={(e) => onChange(e.target.value)}
            renderValue={(value) => (
              <>
                {toTitleCase(
                  StaticResources.promoCategories.find((c) => c.id === value)
                    ?.name || ""
                )}
              </>
            )}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <ListSubheader>Type A</ListSubheader>
            {StaticResources.promoCategories
              .filter((c) => c.type === "A")
              .map((category) => (
                <MenuItem value={category.id}>
                  <ListItemIcon
                    sx={{
                      width: "2.5em",
                      height: "2.5em",
                      textAlign: "center",
                      p: 1,
                      "& svg": { maxWidth: "100%", maxHeight: "100%" },
                    }}
                  >
                    <category.icon />
                  </ListItemIcon>
                  {toTitleCase(category.name)}
                </MenuItem>
              ))}
            <ListSubheader>Type B</ListSubheader>
            {StaticResources.promoCategories
              .filter((c) => c.type === "B")
              .map((category) => (
                <MenuItem value={category.id}>
                  <ListItemIcon
                    sx={{
                      width: "2.5em",
                      height: "2.5em",
                      textAlign: "center",
                      p: 1,
                      "& svg": { maxWidth: "100%", maxHeight: "100%" },
                    }}
                  >
                    <category.icon />
                  </ListItemIcon>
                  {toTitleCase(category.name)}
                </MenuItem>
              ))}
            <ListSubheader>Type C</ListSubheader>
            {StaticResources.promoCategories
              .filter((c) => c.type === "C")
              .map((category) => (
                <MenuItem value={category.id}>
                  <ListItemIcon
                    sx={{
                      width: "2.5em",
                      height: "2.5em",
                      textAlign: "center",
                      p: 1,
                      "& svg": { maxWidth: "100%", maxHeight: "100%" },
                    }}
                  >
                    <category.icon />
                  </ListItemIcon>
                  {toTitleCase(category.name)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      );
    },
    sx: {
      width: 420,
      flexBasis: 420,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "custom",
    field: "materialNumber",
    label: "Material Number",
    sx: {
      width: 420,
      flexBasis: 420,
      flexShrink: 0,
      flexGrow: 1,
    },
    render: (field, value, onChange, values, promoItem) => {
      return (
        <CustomMaterialSelect
          value={value}
          onChange={onChange}
          values={values}
          promoItem={promoItem}
        />
      );
    },
  },

  {
    type: "text",
    field: "shortDescription",
    label: "Short Description",
    sx: {
      width: 250,
      flexBasis: 250,
      flexShrink: 0,
      flexGrow: 1,
    },
  },

  {
    type: "text",
    field: "unitPrice",
    label: "Unit Price",
    sx: {
      width: 100,
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "custom",
    field: "currencyCode",
    label: "Currency",
    sx: {
      width: 140,
      flexBasis: 140,
      flexShrink: 0,
      flexGrow: 1,
    },
    render: (field, value, onChange) => {
      return <CustomCurrencySelect value={value} onChange={onChange} />;
    },
  },
  {
    type: "text",
    field: "pricePer",
    label: "Price Per",
    sx: {
      width: 100,
      flexBasis: 100,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "custom",
    field: "unitOfMeasure",
    label: "Unit of Measure",
    sx: {
      width: 150,
      flexBasis: 150,
      flexShrink: 0,
      flexGrow: 1,
    },
    render: (field, value, onChange, values, promoItem) => {
      return (
        <FormControl fullWidth size="small">
          <InputLabel htmlFor="grouped-select">Units of measure</InputLabel>
          <Select
            size="small"
            fullWidth
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={value}
            label="Age"
            onChange={(e) => onChange(e.target.value)}
          >
            {/* {promoItem.map((currency) => {
              return (
                <MenuItem value={currency.CurrencyKey}>
                  {currency.Description}
                </MenuItem>
              );
            })} */}
          </Select>
        </FormControl>
      );
    },
  },
  {
    type: "custom",
    field: "waysOfBuying",
    label: "Ways of Buying",
    sx: {
      width: 320,
      flexBasis: 320,
      flexShrink: 0,
      flexGrow: 1,
    },
    render: (field, value, onChange, values, promoItem) => {
      return (
        <WaysOfBuyingComponent
          initialValue={value}
          onChange={(value: any[]) => onChange(value)}
        />
      );
    },
  },
  {
    type: "text",
    field: "itemDescription",
    label: "Scope of Application",
    sx: {
      width: 250,
      flexBasis: 250,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "text",
    field: "supplierPartId",
    label: "Supplier Part Id",
    sx: {
      width: 200,
      flexBasis: 200,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "text",
    field: "manufacturerName",
    label: "Manufacturer Name",
    sx: {
      width: 200,
      flexBasis: 200,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "text",
    field: "manufacturerPartNumber",
    label: "Manufacturer Part Number",
    sx: {
      width: 220,
      flexBasis: 220,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "text",
    field: "supplierLeadTime",
    label: "Supplier Lead Time",
    sx: {
      width: 200,
      flexBasis: 200,
      flexShrink: 0,
      flexGrow: 1,
    },
  },
  {
    type: "custom",
    field: "keyWords",
    label: "Key Words",
    sx: {
      width: 420,
      flexBasis: 330,
      flexShrink: 0,
      flexGrow: 1,
    },
    render: (field, value, onChange) => {
      return (
        <MuiChipsInput
          value={value}
          onChange={onChange}
          size="small"
          fullWidth
          label="Key Words"
        />
      );
    },
  },
  {
    type: "custom",
    field: "languageId",
    label: "Language",
    sx: {
      width: 140,
      flexBasis: 140,
      flexShrink: 0,
      flexGrow: 0,
    },
    render: (field, value, onChange) => {
      return (
        <CustomLanguageSelect
          value={value}
          onChange={onChange}
          error={null}
          required={false}
        />
      );
    },
  },
];

export const PromoItemRowSchema = yup.object().shape({
  currencyCode: yup.string().nullable(),
  deleted: yup.boolean(),
  itemDescription: yup.string().required("This field is required."),
  keyWords: yup
    .array()
    .of(yup.string().required())
    .min(1, "At least one key word is required")
    .required("This field is required."),
  languageId: yup.string().required("This field is required."),
  languageTag: yup.string().nullable(),
  manufacturerName: yup.string().nullable(),
  manufacturerPartNumber: yup.string().nullable(),
  marketContactName: yup.string().nullable(),
  matGrp: yup.string().nullable(),
  materialId: yup.string().nullable(),
  materialName: yup.string().nullable(),
  materialNumber: yup.string().nullable(),
  parentMaterialId: yup.string().nullable(),
  pricePer: yup.string().nullable(),
  promoCategoryId: yup.string().required("This field is required."),
  shortDescription: yup.string().required("This field is required."),
  spendCat1Code: yup.string().nullable(),
  spendCat1DisplayValue: yup.string().nullable(),
  spendCat1Id: yup.string().nullable(),
  spendCat2Code: yup.string().nullable(),
  spendCat2DisplayValue: yup.string().nullable(),
  spendCat2Id: yup.string().nullable(),
  spendCat3Code: yup.string().nullable(),
  spendCat3DisplayValue: yup.string().nullable(),
  spendCat3Id: yup.string().nullable(),
  supplierLeadTime: yup.string().nullable(),
  supplierPartId: yup.string().nullable(),
  unitOfMeasure: yup.string().required("This field is required."),
  unitPrice: yup.string().nullable(),
  waysOfBuying: yup.array().of(yup.number()),
  mediaFiles: yup.array().of(
    yup.object().shape({
      fileId: yup.string(),
      mimeType: yup.string(),
      originalFileName: yup.string(),
      persistedFileNameInAzureStorage: yup.string(),
      url: yup.string(),
    })
  ),
});

type PromoItemRowType = yup.InferType<typeof PromoItemRowSchema>;

export const PromoItemRow = (props: {
  promoItem: any;
  defaultSpendCategories: any;
  promoItemIndex: number;
  onRemove: (promoItem: any) => void;
  onDuplicate: (promoItem: any) => void;
  onManageMedia: () => void;
}) => {
  const activeUser = useActiveUser();
  const { values, setValues, setFieldValue, getFieldMeta } =
    useFormikContext<any>();
  const { data: amdrMaterials, isLoading: isLoadingAmdrMaterials } = useQuery({
    queryKey: ["amdrMaterials", props.promoItem.spendCat3Code, values.vendor],
    queryFn: () => {
      return PromoItemsService.getPslAmdrMaterials({
        materialgroup: props.promoItem.spendCat3Code,
        locationCompanyCodes: values.vendor?.companyCodes
          ?.map((companyCode) => companyCode.code)
          .join(","),
        locationMarkets: values.vendor?.markets
          ?.map((market) => market.id)
          .join(","),
        locationZones: values.vendor?.zones?.map((zone) => zone.id).join(","),
      });
    },
    initialData: [],
  });
  const { data: plants, isLoading: plantsLoading } = useQuery({
    queryKey: [
      "plants",
      values.vendor,
      values.materials[props.promoItemIndex].materialNumber,
    ],
    queryFn: () =>
      PromoItemsService.fetchPslAmdrPlantsV2({
        text: null,
        locationZones: values.vendor?.zones?.map((zone) => zone.id).join(","),
        locationMarkets: values.vendor?.markets
          ?.map((market) => market.id)
          .join(","),
        locationCompanyCodes: values.vendor?.companyCodes
          ?.map((companyCode) => companyCode.code)
          .join(","),
        materialNumber: values.materials[props.promoItemIndex].materialNumber,
      }),
    enabled: !!values.materials[props.promoItemIndex].materialNumber,
    initialData: [],
  });

  return (
    <Card
      sx={{
        padding: 2,
        borderRadius: 2,
        backgroundColor: "#F9F9F9",
        mb: 2,
        overflow: "visible",
      }}
    >
      <CardHeader
        title={
          <Breadcrumbs separator={">"}>
            <Tooltip title={props.promoItem.spendCat1DisplayValue}>
              {props.promoItem.spendCat1Code}
            </Tooltip>
            <Tooltip title={props.promoItem.spendCat2DisplayValue}>
              {props.promoItem.spendCat2Code}
            </Tooltip>
            <Tooltip title={props.promoItem.spendCat3DisplayValue}>
              {props.promoItem.spendCat3Code}
            </Tooltip>
          </Breadcrumbs>
        }
        titleTypographyProps={{ color: "primary", variant: "body1" }}
        action={
          <ButtonGroup>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                props.onManageMedia();
              }}
              startIcon={<Collections />}
            >
              Manage Media ({props.promoItem.mediaFiles?.length || 0})
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                props.onDuplicate(props.promoItem);
              }}
              startIcon={<FileCopy />}
            >
              Duplicate
            </Button>
            <Button
              color="primary"
              variant="outlined"
              startIcon={<Close />}
              onClick={() => props.onRemove(props.promoItem)}
            >
              Delete
            </Button>
          </ButtonGroup>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            sx={{
              width: 420,
              flexBasis: 420,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="grouped-select">
                    {`Promo Category 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "promoCategoryId"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  </InputLabel>
                  <Select
                    defaultValue=""
                    id="grouped-select"
                    label="Promo Category"
                    error={
                      !!getFieldMeta(
                        `materials[${props.promoItemIndex}]["promoCategoryId"]`
                      ).error
                    }
                    value={values.materials[props.promoItemIndex][
                      "promoCategoryId"
                    ]?.toString()}
                    onChange={(e) => {
                      setFieldValue(
                        `materials[${props.promoItemIndex}].promoCategoryId`,
                        e.target.value
                      );
                    }}
                    renderValue={(value) => (
                      <>
                        {toTitleCase(
                          StaticResources.promoCategories.find(
                            (c) => c.id === value
                          )?.name || ""
                        )}
                      </>
                    )}
                  >
                    <ListSubheader>Type A</ListSubheader>
                    {StaticResources.promoCategories
                      .filter((c) => c.type === "A")
                      .map((category) => (
                        <MenuItem value={category.id}>
                          <ListItemIcon
                            sx={{
                              width: "2.5em",
                              height: "2.5em",
                              textAlign: "center",
                              p: 1,
                              "& svg": { maxWidth: "100%", maxHeight: "100%" },
                            }}
                          >
                            <category.icon />
                          </ListItemIcon>
                          {toTitleCase(category.name)}
                        </MenuItem>
                      ))}
                    <ListSubheader>Type B</ListSubheader>
                    {StaticResources.promoCategories
                      .filter((c) => c.type === "B")
                      .map((category) => (
                        <MenuItem value={category.id}>
                          <ListItemIcon
                            sx={{
                              width: "2.5em",
                              height: "2.5em",
                              textAlign: "center",
                              p: 1,
                              "& svg": { maxWidth: "100%", maxHeight: "100%" },
                            }}
                          >
                            <category.icon />
                          </ListItemIcon>
                          {toTitleCase(category.name)}
                        </MenuItem>
                      ))}
                    <ListSubheader>Type C</ListSubheader>
                    {StaticResources.promoCategories
                      .filter((c) => c.type === "C")
                      .map((category) => (
                        <MenuItem value={category.id}>
                          <ListItemIcon
                            sx={{
                              width: "2.5em",
                              height: "2.5em",
                              textAlign: "center",
                              p: 1,
                              "& svg": { maxWidth: "100%", maxHeight: "100%" },
                            }}
                          >
                            <category.icon />
                          </ListItemIcon>
                          {toTitleCase(category.name)}
                        </MenuItem>
                      ))}
                  </Select>
                  {!!getFieldMeta(
                    `materials[${props.promoItemIndex}]["promoCategoryId"]`
                  ).error && (
                    <FormHelperText error>
                      {
                        getFieldMeta(
                          `materials[${props.promoItemIndex}]["promoCategoryId"]`
                        ).error
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 420,
              flexBasis: 420,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size="small"
                  disabled={!!isLoadingAmdrMaterials}
                  error={!!getFieldMeta("vendor.materialNumber").error}
                >
                  <InputLabel>Material number</InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    error={
                      !!getFieldMeta(
                        `materials[${props.promoItemIndex}]["materialNumber"]`
                      ).error
                    }
                    value={
                      values.materials[props.promoItemIndex].materialNumber
                    }
                    onChange={(e) =>
                      setFieldValue(
                        `materials[${props.promoItemIndex}].materialNumber`,
                        e.target.value
                      )
                    }
                  >
                    {[
                      ...amdrMaterials,
                      {
                        id: props.defaultSpendCategories.l3.materialCode,
                        name: props.defaultSpendCategories.l3
                          .materialDescription,
                      },
                    ].map((material) => {
                      return (
                        <MenuItem value={material.id}>
                          [{material.id}] {material.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!!getFieldMeta(
                    `materials[${props.promoItemIndex}]["materialNumber"]`
                  ).error && (
                    <FormHelperText error>
                      {
                        getFieldMeta(
                          `materials[${props.promoItemIndex}]["materialNumber"]`
                        ).error
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {!activeUser.hasAboveMarketRole() && (
            <Grid
              item
              sx={{
                width: 420,
                flexBasis: 420,
                flexShrink: 0,
                flexGrow: 1,
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <CustomFastSelect
                    placeholder="Select plants"
                    loading={plantsLoading}
                    isMulti={true}
                    customDisplay={(option) =>
                      `${option.value} - ${option.label}`
                    }
                    initialValue={values.materials[
                      props.promoItemIndex
                    ].plants?.map((p) => ({ value: p.code, label: p.name }))}
                    options={plants.map((v) => ({
                      value: v.code,
                      label: v.name,
                    }))}
                    onChange={(value) =>
                      setFieldValue(
                        `materials[${props.promoItemIndex}].plants`,
                        value.map((v) => ({ code: v.value, name: v.label }))
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            sx={{
              width: 250,
              flexBasis: 250,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Short Description 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "shortDescription"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  error={
                    !!getFieldMeta(
                      `materials[${props.promoItemIndex}]["shortDescription"]`
                    ).error
                  }
                  helperText={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["shortDescription"]`
                    ).error ?? null
                  }
                  value={
                    values.materials[props.promoItemIndex].shortDescription
                  }
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].shortDescription`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 250,
              flexBasis: 250,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Unit Price 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "unitPrice"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={values.materials[props.promoItemIndex].unitPrice}
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].unitPrice`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 140,
              flexBasis: 140,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <CustomCurrencySelect
                  value={values.materials[props.promoItemIndex].currencyCode}
                  onChange={(newValue) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].currencyCode`,
                      newValue
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 100,
              flexBasis: 100,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Price Per 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "pricePer"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={values.materials[props.promoItemIndex].pricePer}
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].pricePer`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 150,
              flexBasis: 150,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel
                    htmlFor="unit-of-measure-select"
                    id="unit-of-measure-label"
                  >
                    {`Unit of Measure 
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "unitOfMeasure"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  </InputLabel>
                  <Select
                    size="small"
                    fullWidth
                    labelId="unit-of-measure-label"
                    id="unit-of-measure-select"
                    error={
                      !!getFieldMeta(
                        `materials[${props.promoItemIndex}]["unitOfMeasure"]`
                      ).error
                    }
                    value={values.materials[props.promoItemIndex].unitOfMeasure}
                    onChange={(e) =>
                      setFieldValue(
                        `materials[${props.promoItemIndex}].unitOfMeasure`,
                        e.target.value
                      )
                    }
                  >
                    {(
                      amdrMaterials
                        .find(
                          (m) =>
                            m.id ==
                            values.materials[props.promoItemIndex]
                              .materialNumber
                        )
                        ?.uoM?.split(",") || ["EA"]
                    ).map((uom) => {
                      return <MenuItem value={uom}>{uom}</MenuItem>;
                    })}
                  </Select>
                  {!!getFieldMeta(
                    `materials[${props.promoItemIndex}]["unitOfMeasure"]`
                  ).error && (
                    <FormHelperText error>
                      {
                        getFieldMeta(
                          `materials[${props.promoItemIndex}]["unitOfMeasure"]`
                        ).error
                      }
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            item
            sx={{
              width: 320,
              flexBasis: 320,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <WaysOfBuyingComponent
                  initialValue={
                    values.materials[props.promoItemIndex].waysOfBuying
                  }
                  onChange={(value: any[]) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].waysOfBuying`,
                      value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid
            item
            sx={{
              width: 250,
              flexBasis: 250,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Scope of Application
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "itemDescription"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  error={
                    !!getFieldMeta(
                      `materials[${props.promoItemIndex}]["itemDescription"]`
                    ).error
                  }
                  helperText={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["itemDescription"]`
                    ).error ?? null
                  }
                  value={values.materials[props.promoItemIndex].itemDescription}
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].itemDescription`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 200,
              flexBasis: 200,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Supplier Part Id
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "supplierPartId"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={values.materials[props.promoItemIndex].supplierPartId}
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].supplierPartId`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 200,
              flexBasis: 200,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Manufacturer Name
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "manufacturerName"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={
                    values.materials[props.promoItemIndex].manufacturerName
                  }
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].manufacturerName`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 220,
              flexBasis: 220,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Manufacturer Part Number
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "manufacturerPartNumber"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={
                    values.materials[props.promoItemIndex]
                      .manufacturerPartNumber
                  }
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].manufacturerPartNumber`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 200,
              flexBasis: 200,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  label={`Supplier Lead Time
                        ${
                          !(
                            PromoItemRowSchema.describe().fields[
                              "supplierLeadTime"
                            ] as any
                          )?.optional
                            ? "*"
                            : ""
                        }`}
                  size="small"
                  fullWidth
                  value={
                    values.materials[props.promoItemIndex].supplierLeadTime
                  }
                  onChange={(e) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].supplierLeadTime`,
                      e.target.value
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 420,
              flexBasis: 330,
              flexShrink: 0,
              flexGrow: 1,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <MuiChipsInput
                  error={
                    !!getFieldMeta(
                      `materials[${props.promoItemIndex}]["keyWords"]`
                    ).error
                  }
                  helperText={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["keyWords"]`
                    ).error
                  }
                  value={values.materials[props.promoItemIndex].keyWords}
                  onChange={(newValue) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].keyWords`,
                      newValue
                    )
                  }
                  size="small"
                  fullWidth
                  label={`Key Words 
                    ${
                      !(PromoItemRowSchema.describe().fields["keyWords"] as any)
                        ?.optional
                        ? "*"
                        : ""
                    }`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: 140,
              flexBasis: 140,
              flexShrink: 0,
              flexGrow: 0,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <CustomLanguageSelect
                  required={
                    !(PromoItemRowSchema.describe().fields["languageId"] as any)
                      ?.optional
                  }
                  error={
                    getFieldMeta(
                      `materials[${props.promoItemIndex}]["languageId"]`
                    ).error
                  }
                  value={values.materials[props.promoItemIndex].languageId}
                  onChange={(newValue) =>
                    setFieldValue(
                      `materials[${props.promoItemIndex}].languageId`,
                      newValue
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PromoItemRow;
