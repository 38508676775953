import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import queryString from "query-string";
import { toast } from "react-toastify";

import { getAccessToken } from "auth/getAccessToken";
import { environment } from "env";

export const MultiOriginValue = "X";

export enum Headers {
  SYSTEM_ALIAS = "X-SystemAlias",
  MULTIORIGINS = "X-MultiOrigin",
}

export function getEnv() {
  // @ts-ignore
  return typeof process.env.REACT_APP_ENV === "undefined"
    ? ""
    : process.env.REACT_APP_ENV;
}

export function httpStatusToText(httpStatusCode) {
  return {
    100: "Continue",
    101: "Switching Protocols",
    103: "Early Hints",
    200: "OK",
    201: "Created",
    202: "Accepted",
    203: "Non-Authoritative Information",
    204: "No Content",
    205: "Reset Content",
    206: "Partial Content",
    300: "Multiple Choices",
    301: "Moved Permanently",
    302: "Found",
    303: "See Other",
    304: "Not Modified",
    307: "Temporary Redirect",
    308: "Permanent Redirect",
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required",
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Failed",
    413: "Payload Too Large",
    414: "URI Too Long",
    415: "Unsupported Media Type",
    416: "Range Not Satisfiable",
    417: "Expectation Failed",
    418: "I'm a teapot",
    422: "Unprocessable Entity",
    425: "Too Early",
    426: "Upgrade Required",
    428: "Precondition Required",
    429: "Too Many Requests",
    431: "Request Header Fields Too Large",
    451: "Unavailable For Legal Reasons",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
    506: "Variant Also Negotiates",
    507: "Insufficient Storage",
    508: "Loop Detected",
    510: "Not Extended",
    511: "Network Authentication Required",
  }[httpStatusCode];
}

export type NSPMap<T = any> = { [key: string]: T };

const BACKEND_URI = environment.REACT_APP_API_BASE ?? "http://localhost:3000";

const handleError = (error: any) => {
  const { response } = error;
  if (!response) {
    if (error.message) toast.error(error.message || "Unknown error");
    throw error;
  }
  const { ...errorObject } = response;
  if (Array.isArray(errorObject?.data)) {
    errorObject.data.forEach((error) => {
      toast.error(error?.message || httpStatusToText(errorObject.status));
    });
  } else {
    toast.error(
      errorObject?.data?.message || httpStatusToText(errorObject.status)
    );
  }
  throw { status: errorObject.status, data: errorObject.data };
};

export class AxiosWrapper {
  private readonly instance: AxiosInstance;

  // @ts-ignore
  constructor(baseURL = `${BACKEND_URI}`) {
    this.instance = axios.create({
      baseURL: baseURL,
      // timeout: 1,
    });

    this.instance.interceptors.request.use(
      async (config) => {
        const token = (await getAccessToken()) ?? null;
        if (token) {
          config.headers.Authorization = "Bearer " + token;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        // switch (error.response.status) {
        //   // case 403:
        //   //   store.dispatch(fetchUserProfileComplete({
        //   //     UserEntity: null,
        //   //     ForbiddenUser: true,
        //   //     ServerError: false,
        //   //     Unauthorized: false,
        //   //     ErrorMessage: error?.response?.data?.message,
        //   //   }));
        //   //   break;
        //   case 401:
        //     store.dispatch(
        //       fetchUserProfileComplete({
        //         UserEntity: null,
        //         ForbiddenUser: false,
        //         ServerError: false,
        //         ServiceUnavailableError: false,
        //         UnknownError: false,
        //         Unauthorized: true,
        //         ErrorMessage: error?.response?.data?.message,
        //       })
        //     );
        //     break;
        // }

        return Promise.reject(error);
      }
    );
  }

  async get<T = any>(
    path: string,
    params?: NSPMap,
    headers?: any
  ): Promise<AxiosResponse<T>> {
    const queryParams: AxiosRequestConfig = params
      ? {
          params: params?.toDTO ? params.toDTO() : params,
          paramsSerializer: (params: any) => {
            return queryString.stringify(params, {
              arrayFormat: "none",
              skipNull: true,
            });
          },
        }
      : {};
    try {
      return await this.instance.get<T>(path, {
        params: queryParams,
        headers,
      });
    } catch (error: any) {
      return handleError(error);
    }
  }

  async getBlob<T = any>(path: string): Promise<AxiosResponse<T>> {
    const queryParams: AxiosRequestConfig = { responseType: "blob" };

    try {
      return await this.instance.get<T>(path, queryParams);
    } catch (error) {
      return handleError(error);
    }
  }

  async post<T = any>(
    path: string,
    payload?: any,
    headers?: any
  ): Promise<AxiosResponse<T>> {
    try {
      return await this.instance.post<T>(path, payload, headers);
    } catch (error) {
      return handleError(error);
    }
  }

  async put<T = any>(
    path: string,
    payload?: any,
    headers?: any
  ): Promise<AxiosResponse<T>> {
    try {
      return await this.instance.put<T>(path, payload, headers);
    } catch (error) {
      return handleError(error);
    }
  }

  async patch<T = any>(
    path: string,
    payload: any,
    headers?: any
  ): Promise<AxiosResponse<T>> {
    try {
      return await this.instance.patch<T>(path, payload, headers);
    } catch (error) {
      return handleError(error);
    }
  }

  async delete<T = any>(path: string, body?): Promise<AxiosResponse<T>> {
    try {
      if (body) {
        return await this.instance.delete<T>(path, { data: body });
      }

      return await this.instance.delete<T>(path);
    } catch (error) {
      return handleError(error);
    }
  }
}
