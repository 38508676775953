import * as R from "ramda";
import * as moment from "moment";
import { AxiosWrapper, Headers, MultiOriginValue } from "auth/axios.wrapper";

interface Dictionary<T> {
  [index: string]: T;
}

export interface TextFilter {
  parentId?: string;
  azureId?: number;
  id: string | number;
  title: string | number;
}

export interface DateRangeFilter {
  startDate: string;
  endDate: string;
}
export interface CreationDateFilter {
  type: string;
  buttonName: string | null;
  startDate: string;
  endDate: string;
}
export interface SimpleTextFilter {
  value: string;
}

export interface SimpleRangeFilter {
  priceFrom: number;
  priceTo: number;
}

export type Filters =
  | TextFilter[]
  | DateRangeFilter
  | SimpleTextFilter
  | SimpleRangeFilter
  | CreationDateFilter;

export interface FilterCategoryView<T = Filters> {
  data: T;
  selected: T;
  key: string;
  label: string;
}

export interface Query {
  resource?: string;
  page: number;
  orderBy: SimpleODataOrder[];
  filters: Dictionary<FilterCategoryView>;
  loading: boolean;
  loaded: boolean;
  term: string;
  top: number;
  rows: number;
}

export enum ContractType {
  ACTIVE = "ACTIVE",
  RECENT = "RECENT",
  FAVORITES = "FAVORITES",
  DRAFT = "DRAFT",
  MRP = "MRP VIEW",
  EXPIRED = "EXPIRED",
}

export enum ContractSource {
  SAP = "SAP",
  CLM_CHILD = "CLM child",
  CLM_PARENT = "CLM parent",
}

export enum DraftContractSource {
  CLONE_CONTRACT = "Clone contract",
  CHANGES_REQUESTS = "Changes requests",
}

export interface ODataQuery {
  path: string;
  resource: string;
  page: number;
  term: string;
  filters: SimpleODataFilter[];
  orderBy: SimpleODataOrder[];
  top: number;
  isHanaSearch?: boolean;
  type?: ContractType;
  source?: ContractSource | DraftContractSource;
}

export type PartialODataQuery = Partial<ODataQuery>;

export interface SimpleODataFilter {
  field: string;
  nestedField?: string;
  value?: string | number;
  operator?: Operators;
  type?: FilterType;
}

export interface SimpleODataOrder {
  field: string;
  direction?: OrderDirection;
}

export enum OrderDirection {
  ASC = "asc",
  DESC = "desc",
}

export enum LogicGate {
  AND = "and",
  OR = "or",
}

export enum FilterType {
  SUBSTRING_OF = "substringof",
}

export enum Operators {
  EQ = "eq",
  GE = "ge",
  LE = "le",
  IN = "in",
}

export enum ODataFormats {
  ISO_DATE = "YYYY-MM-DDTHH:mm:ss",
}

export class ODataFilter {
  private filters: string = "";

  private constructor(_filter?: SimpleODataFilter) {
    if (_filter && _filter.value) {
      this.filters = this.addFilter(_filter);
    }
  }

  static for(filter: SimpleODataFilter): ODataFilter {
    return new ODataFilter(filter);
  }

  static empty(): ODataFilter {
    return new ODataFilter();
  }

  and(f: SimpleODataFilter | ODataFilter): ODataFilter {
    this.filters = this.addFilter(f, LogicGate.AND);
    return this;
  }

  or(f: SimpleODataFilter | ODataFilter): ODataFilter {
    this.filters = this.addFilter(f, LogicGate.OR);
    return this;
  }

  toString(): string {
    return `${this.filters}`;
  }

  private addFilter(
    filter: SimpleODataFilter | ODataFilter,
    lg?: LogicGate
  ): string {
    if (filter instanceof ODataFilter) {
      return this.appendFilter(filter.toString(), lg);
    } else {
      if (R.either(R.isNil, R.isEmpty)(filter.value)) return this.toString();
      if (filter.operator !== Operators.IN) {
        const value = R.or(
          R.is(Number, filter.value),
          moment(filter.value, ODataFormats.ISO_DATE, true).isValid()
        )
          ? `${filter.value}`
          : `'${filter.value}'`;
        return this.appendFilter(
          `${filter.field} ${filter.operator || Operators.EQ} ${value}`,
          lg
        );
      } else {
        return this.appendFilter(
          `${filter.field}/any(a:a${
            filter.nestedField ? "/" + filter.nestedField : ""
          } ${filter.operator || Operators.EQ} (${filter.value}))`,
          lg
        );
      }
    }
  }

  private appendFilter(
    filterString: string,
    operator: LogicGate = LogicGate.AND
  ): string {
    return this.filters
      ? `${this.filters} ${operator} (${filterString})`
      : `(${filterString})`;
  }
}

export function toODataFilter2(
  fcv: FilterCategoryView,
  op?: Operators,
  nestedField: string = undefined
): {
  operator: LogicGate;
  filters: SimpleODataFilter[];
} {
  if (Array.isArray(fcv.selected)) {
    const filters = fcv.selected.map((value) => ({
      field: fcv.key,
      value: value["id"],
    }));
    return {
      operator: LogicGate.OR,
      filters,
    };
  } else if (fcv.selected != null) {
    const value = R.prop("value", <any>fcv.selected);
    if (value) {
      const filters = [
        {
          field: fcv.key,
          value: value,
          operator: op,
          nestedField: nestedField,
        },
      ];
      return {
        operator: LogicGate.OR,
        filters,
      };
      // priceFromTo
    } else if (
      !!R.prop("priceFrom", <any>fcv.selected) ||
      !!R.prop("priceTo", <any>fcv.selected)
    ) {
      const from = R.prop("priceFrom", <any>fcv.selected);
      const to = R.prop("priceTo", <any>fcv.selected);
      let result = [];

      if (from) {
        result.push({
          field: fcv.key,
          value: from,
          operator: Operators.GE,
        });
      }
      if (to) {
        result.push({
          field: fcv.key,
          value: to,
          operator: Operators.LE,
        });
      }
      return {
        operator: LogicGate.AND,
        filters: result,
      };
    } else {
      const startDate = R.prop("startDate", <any>fcv.selected);
      const endDate = R.prop("endDate", <any>fcv.selected);
      if (startDate == null || endDate == null)
        return {
          filters: [],
          operator: LogicGate.AND,
        };

      const filters = [
        {
          field: fcv.key,
          value: startDate,
          operator: Operators.GE,
        },
        {
          field: fcv.key,
          value: endDate,
          operator: Operators.LE,
        },
      ];

      return {
        operator: LogicGate.OR,
        filters,
      };
    }
  } else {
    return {
      filters: [],
      operator: LogicGate.AND,
    };
  }
}

export class ODataClient {
  private inline: string = "";
  private expand: string = "";
  private format: string = "";
  private collection: string = "";
  private filters: string = "";
  private order: string;
  private topSize: number;
  private skipSize: number;
  private selectItems: string[] = [];
  private requestHeaders: { [key: string]: string } = {};

  constructor(private base: string) {}

  resource(collection: string): ODataClient {
    this.collection = collection;
    return this;
  }

  filter(filter: SimpleODataFilter | ODataFilter): ODataClient {
    return this.addFilter(filter);
  }

  withSystemAlias(systemAlias: string, debug = false): ODataClient {
    this.requestHeaders = {
      ...this.requestHeaders,
      [Headers.SYSTEM_ALIAS]: systemAlias,
    };
    return this;
  }

  withMultiOrigin(): ODataClient {
    this.requestHeaders = {
      ...this.requestHeaders,
      [Headers.MULTIORIGINS]: MultiOriginValue,
    };
    return this;
  }

  select(fields: string[]): ODataClient {
    this.selectItems = fields;
    return this;
  }

  and(filter: SimpleODataFilter | ODataFilter): ODataClient {
    return this.addFilter(filter);
  }

  or(filter: SimpleODataFilter | ODataFilter): ODataClient {
    return this.addFilter(filter, LogicGate.OR);
  }

  orderBy(
    field?: string,
    direction: OrderDirection = OrderDirection.ASC
  ): ODataClient {
    if (!field) return this;

    this.order = `$orderby=${field} ${direction}`;
    return this;
  }

  top(top: number = 10): ODataClient {
    this.topSize = top;
    return this;
  }

  skip(skip: number = 0): ODataClient {
    this.skipSize = skip;
    return this;
  }

  count(): ODataClient {
    this.inline = `$inlinecount=allpages`;
    return this;
  }

  toDocFlowHead(): ODataClient {
    this.expand = `$expand=ToDocFlowHead`;
    return this;
  }

  toDocFlowChat(): ODataClient {
    this.expand = `$expand=ToPurchReqItemSet,ToPoApprover,ToMatDocSet,ToInvDetailSet`;
    return this;
  }

  toJson(): ODataClient {
    this.format = `$format=json`;
    return this;
  }

  relative(encode: boolean = true): string {
    let uri = `${this.collection}`;

    if (this.filters && this.filters !== "()")
      uri = this.addQueryParam(uri, `$filter=${this.filters}`);
    if (this.topSize) uri = this.addQueryParam(uri, `$top=${this.topSize}`);
    if (this.skipSize) uri = this.addQueryParam(uri, `$skip=${this.skipSize}`);
    if (this.inline) uri = this.addQueryParam(uri, this.inline);
    if (this.selectItems.length > 0)
      uri = this.addQueryParam(uri, `$select=${this.selectItems.join(",")}`);
    uri = this.addQueryParam(uri, this.order);
    if (this.expand) uri = this.addQueryParam(uri, this.expand);
    if (this.format) uri = this.addQueryParam(uri, this.format);

    return encode ? encodeURIComponent(uri) : uri;
  }

  absolute(encode: boolean = true): string {
    return this.base + this.relative(encode);
  }

  fromQuery(query: PartialODataQuery): ODataClient {
    if (query.path) this.base = query.path;

    if (query.resource) this.resource(query.resource);
    if (query.term) {
      let terms = [{ field: "SearchTerm", value: query.term }];
      if (query.isHanaSearch) terms.push({ field: "IdNo", value: "IS" });
      terms.map((x) => this.addFilter(x));
    }

    if (query.filters) query.filters.map((x) => this.addFilter(x));
    if (query.page) this.skip(query.page * 10);
    else this.skip(0);
    if (query.orderBy)
      query.orderBy.map((x) => this.orderBy(x.field, x.direction));
    if (query.top) this.top(query.top);

    return this;
  }

  fromQ(query: Query): ODataClient {
    if (query.resource) this.resource(query.resource);
    if (query.page) {
      if (query.page) this.skip(query.page * query.rows);
      else this.skip(0);
    }
    if (query.orderBy)
      query.orderBy.map((x) => this.orderBy(x.field, x.direction));

    if (query.filters) {
      const list = R.toPairs(query.filters);
      const filters = list.map(([key, filter], index) =>
        toODataFilter2(filter)
      );
      const fullFilters = filters.reduce(
        (previousValue, currentValue) =>
          previousValue.and(
            currentValue.filters.length &&
              currentValue.filters.reduce(
                (x, y) =>
                  currentValue.operator === LogicGate.AND ? x.and(y) : x.or(y),
                ODataFilter.empty()
              )
          ),
        ODataFilter.empty()
      );
      this.filters = fullFilters.toString();
      this.addFilter({ field: "IdNo", value: "SH" });
    }
    if (query.term) {
      const tempdata = query.term.split(",");
      this.addFilter({ field: tempdata[0], value: tempdata[1] });
    }
    if (query.rows) {
      if (query.rows === 10) this.top(query.top);
      else this.top(query.rows);
    }
    return this;
  }

  nbsFromQ(query: Query): ODataClient {
    if (query.resource) this.resource(query.resource);
    if (query.page) this.skip(query.page * query.rows);
    else this.skip(0);
    if (query.orderBy)
      query.orderBy.map((x) => this.orderBy(x.field, x.direction));
    if (query.filters) {
      const list = R.toPairs(query.filters);
      const filters = list.map(([key, filter], index) =>
        toODataFilter2(filter)
      );
      const fullFilters = filters.reduce(
        (previousValue, currentValue) =>
          previousValue.and(
            currentValue.filters.length &&
              currentValue.filters.reduce(
                (x, y) =>
                  currentValue.operator === LogicGate.AND ? x.and(y) : x.or(y),
                ODataFilter.empty()
              )
          ),
        ODataFilter.empty()
      );
      this.filters = fullFilters.toString();
    }

    if (query.term) {
      const tempdata = query.term.split(",");
      this.addFilter({ field: tempdata[0], value: tempdata[1] });
    }
    if (query.top) this.top(query.top);
    else this.top(query.rows);

    return this;
  }

  fromQExcel(query: Query): ODataClient {
    if (query.resource) this.resource(query.resource);
    if (query.page) this.skip(query.page * 10);
    else this.skip(0);
    if (query.orderBy)
      query.orderBy.map((x) => this.orderBy(x.field, x.direction));
    if (query.filters) {
      const list = R.toPairs(query.filters);
      const filters = list.map(([key, filter], index) =>
        toODataFilter2(filter)
      );
      const fullFilters = filters.reduce(
        (previousValue, currentValue) =>
          previousValue.and(
            currentValue.filters.length &&
              currentValue.filters.reduce(
                (x, y) =>
                  currentValue.operator === LogicGate.AND ? x.and(y) : x.or(y),
                ODataFilter.empty()
              )
          ),
        ODataFilter.empty()
      );
      this.filters = fullFilters.toString();
      this.addFilter({ field: "IdNo", value: "SH" });
      // this.addFilter({ field: 'Export', value: 'Excel' })
    }
    if (query.term) {
      this.addFilter({ field: "SearchTerm", value: query.term });
    }
    if (query.top) this.top(query.top);

    return this;
  }

  fromQInvoices(query: Query): ODataClient {
    if (query.resource) this.resource(query.resource);
    if (query.page) this.skip(query.page * query.rows);
    else this.skip(0);
    if (query.orderBy)
      query.orderBy.map((x) => this.orderBy(x.field, x.direction));
    if (query.filters) {
      const list = R.toPairs(query.filters);
      const filters = list.map(([key, filter], index) =>
        toODataFilter2(filter)
      );
      const fullFilters = filters.reduce(
        (previousValue, currentValue) =>
          previousValue.and(
            currentValue.filters.length &&
              currentValue.filters.reduce(
                (x, y) =>
                  currentValue.operator === LogicGate.AND ? x.and(y) : x.or(y),
                ODataFilter.empty()
              )
          ),
        ODataFilter.empty()
      );
      this.filters = fullFilters.toString();
      //this.addFilter({field: 'IdNo', value: 'SH'});
    }
    if (query.term) {
      const tempdata = query.term.split(",");
      this.addFilter({ field: tempdata[0], value: tempdata[1] });
    }
    if (query.rows === 10) this.top(query.top);
    else this.top(query.rows);

    return this;
  }

  fromQInvoicesExcel(query: Query): ODataClient {
    if (query.resource) this.resource(query.resource);
    if (query.page) this.skip(query.page * 10);
    else this.skip(0);
    if (query.orderBy)
      query.orderBy.map((x) => this.orderBy(x.field, x.direction));
    if (query.filters) {
      const list = R.toPairs(query.filters);
      const filters = list.map(([key, filter], index) =>
        toODataFilter2(filter)
      );
      const fullFilters = filters.reduce(
        (previousValue, currentValue) =>
          previousValue.and(
            currentValue.filters.length &&
              currentValue.filters.reduce(
                (x, y) =>
                  currentValue.operator === LogicGate.AND ? x.and(y) : x.or(y),
                ODataFilter.empty()
              )
          ),
        ODataFilter.empty()
      );
      this.filters = fullFilters.toString();
      // this.addFilter({ field: 'Export', value: 'Excel' })
    }
    if (query.term) {
      this.addFilter({ field: "SearchTerm", value: query.term });
    }
    if (query.top) this.top(query.top);

    return this;
  }

  fromQstatus(query: Query): ODataClient {
    if (query.resource) this.resource(query.resource);
    if (query.page) this.skip(query.page * 10);
    else this.skip(0);
    if (query.filters) {
      const list = R.toPairs(query.filters);
      const filters = list.map(([key, filter], index) =>
        toODataFilter2(filter)
      );
      const fullFilters = filters.reduce(
        (previousValue, currentValue) =>
          previousValue.and(
            currentValue.filters.length &&
              currentValue.filters.reduce(
                (x, y) =>
                  currentValue.operator === LogicGate.AND ? x.and(y) : x.or(y),
                ODataFilter.empty()
              )
          ),
        ODataFilter.empty()
      );
      this.filters = fullFilters.toString();
      this.addFilter({ field: "IdNo", value: "SH" });
    }
    if (query.term) {
      const tempdata = query.term.split(",");
      this.addFilter({ field: tempdata[0], value: tempdata[1] });
    }
    if (query.top) this.top(query.top);

    return this;
  }

  fromQstatusInv(query: Query): ODataClient {
    if (query.resource) this.resource(query.resource);
    if (query.page) this.skip(query.page * 10);
    else this.skip(0);
    if (query.filters) {
      const list = R.toPairs(query.filters);
      const filters = list.map(([key, filter], index) =>
        toODataFilter2(filter)
      );
      const fullFilters = filters.reduce(
        (previousValue, currentValue) =>
          previousValue.and(
            currentValue.filters.length &&
              currentValue.filters.reduce(
                (x, y) =>
                  currentValue.operator === LogicGate.AND ? x.and(y) : x.or(y),
                ODataFilter.empty()
              )
          ),
        ODataFilter.empty()
      );
      this.filters = fullFilters.toString();
      this.addFilter({ field: "IdNo", value: "C1" });
    }
    if (query.term) {
      const tempdata = query.term.split(",");
      this.addFilter({ field: tempdata[0], value: tempdata[1] });
    }
    if (query.top) this.top(query.top);

    return this;
  }

  async execute() {
    return await new AxiosWrapper().get(this.absolute(), null, {
      headers: this.requestHeaders,
    });
  }

  async run() {
    return await new AxiosWrapper().get(this.absolute(), null, {
      headers: this.requestHeaders,
    });
  }

  private addQueryParam(uri: string, queryParam: string): string {
    if (!queryParam) return uri;

    return uri.includes("?") ? `${uri}&${queryParam}` : `${uri}?${queryParam}`;
  }

  private addFilter(
    filter: SimpleODataFilter | ODataFilter,
    logicGate: LogicGate = LogicGate.AND
  ): ODataClient {
    if (filter instanceof ODataFilter) {
      this.filters = this.appendFilter(filter.toString(), logicGate);
    } else {
      if (!filter.value) return this;
      this.filters = this.appendFilter(
        this.getFilterAsString(filter),
        logicGate
      );
    }
    return this;
  }

  private appendFilter(
    filterString: string,
    operator: LogicGate = LogicGate.AND
  ): string {
    const simpleFilter = filterString ? `(${filterString})` : filterString;
    return this.filters
      ? `${this.filters} ${operator} ${simpleFilter}`
      : simpleFilter;
  }

  private getFilterAsString(filter: SimpleODataFilter): string {
    switch (filter.type) {
      case FilterType.SUBSTRING_OF:
        return `substringof('${filter.value}', ${filter.field})`;
      default:
        return `${filter.field} ${filter.operator || Operators.EQ} '${
          filter.value
        }'`;
    }
  }
}
