import { Close, Delete, Remove } from "@mui/icons-material";
import {
  Drawer as MuiDrawer,
  Box,
  Toolbar,
  Typography,
  AppBar,
  IconButton,
} from "@mui/material";
import React, { ReactNode, useState } from "react";

export const Drawer = (props: {
  open?: boolean;
  onClose?: () => void;
  children?: ReactNode;
  title?: string;
  headerContent?: ReactNode;
  footerContent?: ReactNode;
}) => {
  const { open, onClose, children } = props;
  return (
    <MuiDrawer
      anchor={"right"}
      open={open}
      onClose={() => onClose && onClose()}
      PaperProps={{
        sx: { p: 0, pt: 8 },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.headerContent ? (
          <Box>{props.headerContent}</Box>
        ) : (
          <AppBar position="static">
            <Toolbar>
              {props.title && (
                <Typography variant="h5" sx={{ flex: "1 1 auto" }}>
                  {props.title}
                </Typography>
              )}
              {props.onClose && (
                <IconButton color="inherit" onClick={() => onClose()}>
                  <Close />
                </IconButton>
              )}
            </Toolbar>
          </AppBar>
        )}
        <Box
          sx={{
            p: 2,
            flex: "1 1 100%",
            width: 500,
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "auto",
          }}
        >
          {props.children}
        </Box>
        {props.footerContent && (
          <Box
            sx={{
              p: 2,
              boxShadow: "0 -10px 10px -10px rgba(0,0,0,0.2)",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {props.footerContent}
          </Box>
        )}
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
